// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".payment-choice {\n  border: 0.5px solid #c7c7c7;\n  width: 500px;\n  height: 70px;\n  padding: 10px;\n  flex-direction: row-reverse;\n  justify-content: center;\n  border-radius: 10px;\n}\n.payment-choice .v-label {\n  justify-content: center;\n}\n.cart-list {\n  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n}\n.cart-package-count {\n  font-family: Poppins, serif;\n  font-weight: normal;\n  color: #3d3d3d;\n  font-size: 16px;\n}\n.purchase-content {\n  margin-top: 15px;\n  padding: 20px;\n  background: #ffffff;\n  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);\n  border-radius: 7px;\n}\n.purchase-content p {\n  font-family: Poppins, serif;\n  text-align: center;\n  color: #3d3d3d;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
