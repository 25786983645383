<template>
  <m-container>
    <v-container>
      <v-btn
        outlined
        color="primary"
        style="background: white"
        rounded
        @click="goToStudentDashbord"
        ><v-icon left>arrow_back</v-icon
        >{{ !$language ? "My Courses" : "আমার কোর্সসমূহ" }}</v-btn
      >
    </v-container>
    <page-title
      :page-title="!$language ? 'Checkout' : 'চেকআউট '"
      class="mt-5"
    ></page-title>
    <lazy-load :item="order">
      <m-container v-if="order">
        <submission-alert-message
          should-scroll-to-top
          ref="banner"
          v-model="showBannerCondition"
          :message="bannerMessage"
          class="mb-3"
        ></submission-alert-message>
        <m-row>
          <m-col cols="8">
            <v-card>
              <v-card-title>{{
                !$language
                  ? "Select a Payment method"
                  : "একটি পেমেন্ট মেথড সিলেক্ট করুন"
              }}</v-card-title>
              <v-card-subtitle>{{
                !$language
                  ? "If total amount is 0, no need to select any payment method"
                  : "মোট এমাউন্ট 0 হলে, কোনো পেমেন্ট মেথড সিলেক্ট করার প্রয়োজন নেই"
              }}</v-card-subtitle>
              <v-card-text>
                <div class="d-flex justify-center">
                  <v-radio-group v-model="method">
                    <v-radio
                      v-if="order.currency === 'usd'"
                      value="card"
                      on-icon="mdi-checkbox-marked-circle"
                      off-icon="null"
                      class="payment-choice"
                    >
                      <template v-slot:label>
                        <div
                          class="d-flex flex-column justify-center align-center"
                        >
                          <v-img
                            :src="
                              require('@ef/modules/cart/assets/card-logo.svg')
                            "
                          ></v-img>
                          <div v-if="amountTooLess" class="error--text">
                            You can't process &lt; {{ thresholdAmount }}
                            {{ order.currency.toUpperCase() }} via card.
                          </div>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio
                      v-if="order.currency === 'bdt'"
                      value="bkash"
                      on-icon="mdi-checkbox-marked-circle"
                      off-icon="marked"
                      class="payment-choice"
                    >
                      <template v-slot:label>
                        <div class="d-flex justify-center">
                          <v-img
                            :src="
                              require('@ef/modules/cart/assets/bkash-logo.svg')
                            "
                          ></v-img>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio
                      v-if="order.currency === 'bdt'"
                      value="nagad"
                      on-icon="mdi-checkbox-marked-circle"
                      off-icon="marked"
                      class="payment-choice"
                    >
                      <template v-slot:label>
                        <div class="d-flex justify-center">
                          <v-img
                            :src="
                              require('@ef/modules/cart/assets/nagad-logo.svg')
                            "
                            height="60px"
                          ></v-img>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio
                      v-if="order.currency === 'bdt'"
                      value="sslcommerz"
                      :on-icon="
                        method != '' && method == 'sslcommerz'
                          ? 'mdi-checkbox-marked-circle'
                          : 'marked'
                      "
                      off-icon="marked"
                      class="payment-choice"
                    >
                      <template v-slot:label>
                        <div class="d-flex justify-center">
                          <v-img
                            :src="
                              require('@ef/modules/cart/assets/card-logo.svg')
                            "
                          ></v-img>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>
            </v-card>
          </m-col>
          <m-col>
            <v-card height="300px" elevation="0">
              <v-card-text>
                <div
                  style="height: 210px"
                  class="d-flex flex-column align-center justify-center"
                >
                  <h2 class="cart-package-count">
                    {{ !$language ? "Total:" : "সর্বমোট:" }}
                  </h2>
                  <h2>{{ order.amount }} {{ order.currency.toUpperCase() }}</h2>
                </div>
                <v-btn
                  depressed
                  :loading="loading"
                  color="primary"
                  block
                  @click="checkPaymentMethod"
                  >{{
                    !$language ? "Proceed to Payment" : "পেমেন্ট করতে অগ্রসর হও"
                  }}</v-btn
                >
              </v-card-text>
            </v-card>
          </m-col>
        </m-row>
      </m-container>
    </lazy-load>
  </m-container>
</template>

<script>
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";

export default {
  name: "Checkout",
  mixins: [],
  components: { SubmissionAlertMessage },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    coupon_code: {
      type: String,
    },
  },
  data() {
    return {
      method: "",
      loading: false,
      firstLoad: undefined,
      showBannerCondition: false,
    };
  },
  computed: {
    bannerMessage() {
      return this.$language
        ? "মূল্য পরিশোধ মাধ্যম বেছে নেয়া বাধ্যতামূলক"
        : "Must select a payment method";
    },
    valid() {
      if (this.order.amount === "0") return true;
      if (this.method === "card") return !this.amountTooLess;
      return ["card", "bkash", "nagad", "sslcommerz"].includes(this.method);
    },
    amountTooLess() {
      return parseInt(this.order.amount) < this.thresholdAmount;
    },
    thresholdAmount() {
      return this.order.currency === "bdt" ? 100 : 2;
    },
    order() {
      return this.$store.state.cart.orders[this.orderId];
    },
    cart() {
      return this.$store.state.cart.cart;
    },
  },
  async created() {
    //await this.$store.dispatch("cart/getOrder", { orderId: this.orderId, coupon_code: this.coupon_code });
    await this.$store.dispatch("cart/getOrder", { orderId: this.orderId });
    this.firstLoad = true;
  },
  watch: {
    valid(value) {
      if (value) this.showBannerCondition = false;
    },
  },
  methods: {
    async pay() {
      if (this.loading) return;
      if (!this.valid) {
        if (!this.showBannerCondition) this.showBannerCondition = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      try {
        this.loading = true;
        const resp = await this.$store.dispatch("cart/pay", {
          amount: this.order.amount,
          method: this.method,
          orderId: this.orderId,
          agent: "student",
        });
        if (this.order.amount == 0) {
          this.$logger.log(resp);
          window.location.href = "/students";
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loading = false;
      }
    },
    checkPaymentMethod() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      this.firstLoad = false;
      if (this.method == "sslcommerz" && this.amountTooLess) {
        this.$root.$emit("alert", [
          undefined,
          `To use Credit / Debit Cards or Mobile / Internet Banking; minimum payment is 100 BDT or more. Please use another payment method.`,
        ]);
        this.method = "";
      } else {
        this.pay();
      }
    },
    goToStudentDashbord() {
      this.$router.push("/students");
    },
  },
};
</script>

<style lang="scss">
.payment-choice {
  border: 0.5px solid #c7c7c7;
  width: 500px;
  height: 70px;
  padding: 10px;
  flex-direction: row-reverse;
  justify-content: center;
  border-radius: 10px;

  .v-label {
    justify-content: center;
  }
}
.cart-list {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.cart-package-count {
  font-family: Poppins, serif;
  font-weight: normal;
  color: $text;
  font-size: 16px;
}
.purchase-content {
  margin-top: 15px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  p {
    font-family: Poppins, serif;
    text-align: center;
    color: $text;
  }
}
</style>
